<template>

    <div class="botIcon chatbot style4" :style="{ color: $settings.font_family }"
        :class="showFloatingDiv ? 'showBotSubject': ''" id="show_style4">
        <div class="chatboticon4" @click="toggleFloatingDiv"
            :style="{ background: backgroundLinear($settings.chat_color) }">
            <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                    width="40" height="40" x="0" y="0" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;background: transparent;" xml:space="preserve" class="">
                    <g>
                        <path
                            d="M304 96H112c-8.832 0-16 7.168-16 16s7.168 16 16 16h192c8.832 0 16-7.168 16-16s-7.168-16-16-16zM240 160H112c-8.832 0-16 7.168-16 16s7.168 16 16 16h128c8.832 0 16-7.168 16-16s-7.168-16-16-16z"
                            fill="#FFFFFF" opacity="1" data-original="#000000" class="" />
                        <path
                            d="M352 0H64C28.704 0 0 28.704 0 64v320c0 6.208 3.584 11.872 9.216 14.496A16.232 16.232 0 0 0 16 400c3.68 0 7.328-1.28 10.24-3.712L117.792 320H352c35.296 0 64-28.704 64-64V64c0-35.296-28.704-64-64-64zm32 256c0 17.632-14.336 32-32 32H112c-3.744 0-7.36 1.312-10.24 3.712L32 349.856V64c0-17.632 14.336-32 32-32h288c17.664 0 32 14.368 32 32v192z"
                            fill="#FFFFFF" opacity="1" data-original="#000000" class="" />
                        <path
                            d="M448 128c-8.832 0-16 7.168-16 16s7.168 16 16 16c17.664 0 32 14.368 32 32v270.688l-54.016-43.2A16.12 16.12 0 0 0 416 416H192c-17.664 0-32-14.368-32-32v-16c0-8.832-7.168-16-16-16s-16 7.168-16 16v16c0 35.296 28.704 64 64 64h218.368l75.616 60.512A16.158 16.158 0 0 0 496 512c2.336 0 4.704-.512 6.944-1.568A16.05 16.05 0 0 0 512 496V192c0-35.296-28.704-64-64-64z"
                            fill="#FFFFFF" opacity="1" data-original="#000000" class="" />
                    </g>
                </svg>
            </div>
        </div>

        <div class="Layout Layout-open Layout-expand Layout-right messages-wrapper4">
            <div class="Messenger_messenger">
                <div class="messenger_header" :style="{ background: $settings.chat_color }">
                    <div class="icon_fullscreen">
                        <a href="#" id="fullscreen" @click="fullscreen">
                            <i class="fa-solid fa-expand" id="fullscreen-icon" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div class="logo_organization d-flex gap-2 align-items-center">
                        <img v-if="$settings.logo" :src="$settings.logo" id="style4_logo">
                        <!-- <p class="organization_name" :style="{ color: $settings.text_color } ">
                            {{$settings.organization.name }}</p> -->
                    </div>
                    <div class=" chat_close_icon" @click="toggleFloatingDiv">
                        <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                    </div>
                </div>

                <div class="Messenger_content" :style="{ background: $settings.chat_color }">
                    <div class="Messages">
                        <div class="Messages_list Messages_list4" id="Messages_list4" ref="messagesList"
                            style="overflow-y: auto;">
                            <div v-for="(item, index) in messages" :key="index" class="main-mesage">
                                <div class="msg user" v-if="item.role == 'user' && item.message_type == 'text'">
                                    <!-- <span class="avtr">
                                        <img :src="$settings.user_icon">
                                    </span> -->
                                    <span class="responsText"
                                        :style="{ color: $settings.text_color, background: rgbaFromHex($settings.chat_color, 0.2), borderColor: $settings.chat_color }">
                                        <span v-html="item.message"></span>
                                        <span class="date-span text-muted fs-14 d-flex w-100 position-absolute mt-2">{{
                                            item.time }}</span>
                                    </span>

                                </div>
                                <div class="msg user" v-if="item.role == 'user' && item.message_type == 'file'"
                                    :id="item.file_id">
                                    <!-- <span class="avtr">
                                        <img :src="$settings.user_icon">
                                    </span> -->
                                    <span class="responsText"
                                        :style="{ color: $settings.text_color, background: rgbaFromHex($settings.chat_color, 0.2), border: $settings.chat_color }">
                                        <div class="img-file" v-if="!item.deleted">
                                            <img v-if="item.file_extension == 'image'" :src="item.file_source"
                                                :alt="item.file_type">
                                            <i v-if="item.file_extension == 'pdf'" style="font-size: 30px;"
                                                class="fa-regular fa-file-pdf theme-color"></i>
                                            <i v-if="item.file_extension == 'word'" style="font-size: 30px;"
                                                class="fa-regular fa-file-word theme-color"></i>
                                            <i v-if="item.file_extension == 'excel'" style="font-size: 30px;"
                                                class="fa-regular fa-file-excel theme-color"></i>
                                            <i v-if="item.file_extension == 'file'" style="font-size: 30px;"
                                                class="fa-regular fa-file theme-color"></i>

                                            <div v-if="item.loading" class="spinner-border" role="status">
                                                <span class="sr-only">{{ $t("Loading") }}...</span>
                                            </div>
                                            <div v-else v-text="item.file_type"></div>
                                        </div>
                                        <div v-else>
                                            <i class="fa-solid fa-file-circle-xmark"></i>
                                            {{ $t("File was deleted") }}
                                        </div>
                                        <span class="date-span text-muted fs-14 d-flex w-100 position-absolute mt-2">{{
                                            item.time }}</span>
                                    </span>
                                </div>
                                <div class="msg" v-if="item.role != 'user'">
                                    <!-- <span class="avtr">
                                        <img :src="$settings.bot_icon">
                                    </span> -->
                                    <span class="responsText">
                                        <!-- <span v-if="item.message.trim() === ''">{{ $t("typing") }}...</span> -->
                                        <span v-if="item.message.trim() === ''">
                                            <span class="typing">
                                                <span :style="{ background: $settings.chat_color }"></span>
                                                <span :style="{ background: $settings.chat_color }"></span>
                                                <span :style="{ background: $settings.chat_color }"></span>
                                            </span>
                                        </span>
                                        <span v-else v-html="item.message"></span>
                                        <span class="date-span text-muted fs-14 d-flex w-100 position-absolute mt-2">{{
                                            item.time }}</span>
                                        <!--  -->
                                        <span class="review_response-feedback"
                                            :style="{ color: $settings.chat_color, borderColor: $settings.chat_color }"
                                            v-if="$settings.organization.allow_feedback && item.allow_feedback"
                                            :message_id="item.message_id">
                                            <a href="#popup" @click="handleClick">
                                                <svg fill="#000000" :style="{ fill: $settings.chat_color }" width="25px"
                                                    height="25px" viewBox="0 0 32 32"
                                                    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
                                                    version="1.1" xml:space="preserve"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:serif="http://www.serif.com/"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                                    <g id="Icon">
                                                        <path
                                                            d="M21.045,20.004c0,0.552 0.448,1 1,1l5,-0c0.552,-0 1,-0.448 1,-1l0,-15c0,-0.552 -0.448,-1 -1,-1l-5,-0c-0.552,-0 -1,0.448 -1,1l0,15Zm2,-1l0,-13c0,-0 3,-0 3,-0c0,-0 0,13 0,13l-3,-0Z" />
                                                        <path
                                                            d="M15.116,26.068l3.841,-7.614c0.071,-0.139 0.107,-0.294 0.107,-0.45l0,-11c0,-0.266 -0.106,-0.521 -0.294,-0.709l-2.01,-2c-0.187,-0.186 -0.441,-0.291 -0.705,-0.291l-6.351,-0c-1.43,-0 -2.661,1.009 -2.942,2.411l-2.204,11.01c-0.176,0.882 0.052,1.796 0.623,2.492c0.57,0.695 1.422,1.098 2.322,1.097l2.961,-0.003l-1.021,3.105c-0.504,1.533 0.331,3.185 1.865,3.689c0.048,0.016 0.097,0.032 0.146,0.048c1.508,0.496 3.131,-0.303 3.664,-1.789l-0.002,0.004Zm-1.815,-0.841c-0.023,0.044 -0.042,0.09 -0.058,0.138c-0.159,0.484 -0.68,0.748 -1.165,0.588l-0.146,-0.048c-0.484,-0.159 -0.748,-0.681 -0.589,-1.165c0,0 1.453,-4.418 1.453,-4.418c0.1,-0.305 0.048,-0.639 -0.141,-0.899c-0.188,-0.26 -0.489,-0.414 -0.81,-0.413l-4.344,0.004c-0.3,0 -0.584,-0.134 -0.774,-0.366c-0.19,-0.232 -0.266,-0.536 -0.208,-0.83c0,-0 2.204,-11.01 2.204,-11.01c0.094,-0.468 0.504,-0.804 0.981,-0.804c-0,-0 5.938,-0 5.938,-0c-0,-0 1.422,1.415 1.422,1.415c0,0 0,10.347 0,10.347c0,-0 -3.763,7.461 -3.763,7.461Z" />
                                                    </g>
                                                </svg>
                                            </a>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="preview-files" :style="{ display: shouldDisplayPreview ? 'block' : 'none' }">
                        <div class="files" id="files-list-container">
                            <span v-for="(file, index) in files" :key="index" :fileIndex="file.id" :title="file.name"
                                :url="file.url" class="file-item">
                                <svg v-if="!file.is_image" @click="previewImage" :style="{ fill: $settings.chat_color }"
                                    fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px"
                                    viewBox="0 0 470.586 470.586" xml:space="preserve">
                                    <g>
                                        <path
                                            d="M327.081,0H90.234c-15.9,0-28.854,12.959-28.854,28.859v412.863c0,15.924,12.953,28.863,28.854,28.863H380.35
                                            c15.917,0,28.855-12.939,28.855-28.863V89.234L327.081,0z M333.891,43.184l35.996,39.121h-35.996V43.184z M384.972,441.723
                                            c0,2.542-2.081,4.629-4.634,4.629H90.234c-2.551,0-4.62-2.087-4.62-4.629V28.859c0-2.548,2.069-4.613,4.62-4.613h219.41v70.181
                                            c0,6.682,5.444,12.099,12.129,12.099h63.198V441.723z M131.858,161.048l-25.29-99.674h18.371l11.688,49.795
                                            c1.646,6.954,3.23,14.005,4.592,20.516c1.555-6.682,3.425-13.774,5.272-20.723l13.122-49.583h16.863l11.969,49.929
                                            c1.552,6.517,3.094,13.243,4.395,19.742c1.339-5.784,2.823-11.718,4.348-17.83l0.562-2.217l12.989-49.618h17.996l-28.248,99.673
                                            h-16.834l-12.395-51.173c-1.531-6.289-2.87-12.052-3.975-17.693c-1.292,5.618-2.799,11.366-4.643,17.794l-13.964,51.072h-16.819
                                            V161.048z M242.607,139.863h108.448c5.013,0,9.079,4.069,9.079,9.079c0,5.012-4.066,9.079-9.079,9.079H242.607
                                            c-5.012,0-9.079-4.067-9.079-9.079C233.529,143.933,237.596,139.863,242.607,139.863z M360.135,209.566
                                            c0,5.012-4.066,9.079-9.079,9.079H125.338c-5.012,0-9.079-4.067-9.079-9.079c0-5.013,4.066-9.079,9.079-9.079h225.718
                                            C356.068,200.487,360.135,204.554,360.135,209.566z M360.135,263.283c0,5.012-4.066,9.079-9.079,9.079H125.338
                                            c-5.012,0-9.079-4.067-9.079-9.079c0-5.013,4.066-9.079,9.079-9.079h225.718C356.068,254.204,360.135,258.271,360.135,263.283z
                                            M360.135,317c0,5.013-4.066,9.079-9.079,9.079H125.338c-5.012,0-9.079-4.066-9.079-9.079c0-5.012,4.066-9.079,9.079-9.079h225.718
                                            C356.068,307.921,360.135,311.988,360.135,317z M360.135,371.474c0,5.013-4.066,9.079-9.079,9.079H125.338
                                            c-5.012,0-9.079-4.066-9.079-9.079s4.066-9.079,9.079-9.079h225.718C356.068,362.395,360.135,366.461,360.135,371.474z" />
                                    </g>
                                </svg>
                                <svg v-else @click="previewImage" width="20px" height="20px" viewBox="0 0 32 32"
                                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                                        sketch:type="MSPage">
                                        <g id="Icon-Set" sketch:type="MSLayerGroup"
                                            transform="translate(-360.000000, -99.000000)" fill="#000000"
                                            :style="{ fill: $settings.chat_color }">
                                            <path
                                                d="M368,109 C366.896,109 366,108.104 366,107 C366,105.896 366.896,105 368,105 C369.104,105 370,105.896 370,107 C370,108.104 369.104,109 368,109 L368,109 Z M368,103 C365.791,103 364,104.791 364,107 C364,109.209 365.791,111 368,111 C370.209,111 372,109.209 372,107 C372,104.791 370.209,103 368,103 L368,103 Z M390,116.128 L384,110 L374.059,120.111 L370,116 L362,123.337 L362,103 C362,101.896 362.896,101 364,101 L388,101 C389.104,101 390,101.896 390,103 L390,116.128 L390,116.128 Z M390,127 C390,128.104 389.104,129 388,129 L382.832,129 L375.464,121.535 L384,112.999 L390,118.999 L390,127 L390,127 Z M364,129 C362.896,129 362,128.104 362,127 L362,126.061 L369.945,118.945 L380.001,129 L364,129 L364,129 Z M388,99 L364,99 C361.791,99 360,100.791 360,103 L360,127 C360,129.209 361.791,131 364,131 L388,131 C390.209,131 392,129.209 392,127 L392,103 C392,100.791 390.209,99 388,99 L388,99 Z"
                                                id="image-picture" sketch:type="MSShapeGroup"></path>
                                        </g>
                                    </g>
                                </svg>
                                <a href="" @click="deleteFile" class="btn-delete">
                                    <span :style="{ color: $settings.chat_color }">×</span>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="form_wrapper">
                        <form v-if="showForm" id="messenger4" @submit="customSubmit" class="style4_form"
                            :style="{ background: rgbaFromHex($settings.chat_color, 0.2) }">
                            <div class="upload-form" id="upload-container" style="display: none;">
                                <label class="form__container" id="upload-container">
                                    <a href="#popup-attachments" id="file-type"><i
                                            :style="{ color: $settings.chat_color }"
                                            class="fa-regular fa-images"></i></a>
                                    <input class="form__file lockable-input" ref="uploadFiles" id="upload-files"
                                        name="file" type="file" :accept="acceptString"
                                        style="visibility: hidden;width: 0;" @change="sendQuestion" />
                                </label>
                            </div>
                            <input type="hidden" name="delete_file_id" v-model="deleteFileId" />
                            <div class="Input Input-blank">
                                <input name="msg4" v-model="question" class="Input_field lockable-input"
                                    :placeholder="$t(`send_a_message`)">
                                <button type="submit" class="Input_button Input_button-send lockable-input">
                                    <div class="Icon">
                                        <i class="fa fa-paper-plane" :style="{ color: $settings.chat_color }"></i>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <div v-else class="d-flex align-items-center justify-content-center" id="force-refresh">
                            <Button class="btn btn-primary px-3" @click="reloadPage"
                                :style="{ color: 'black', background: rgbaFromHex($settings.chat_color, 0.2), width:'100%' }">
                                <svg fill="#000000" height="18px" width="18px" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 489.645 489.645" xml:space="preserve">
                                    <g>
                                        <path
                                            d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2C414.856,432.511,548.256,314.811,460.656,132.911z" />
                                    </g>
                                </svg>
                                {{ $t("Something went wrong, please refresh") }}
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- modal -->
    <div id="popup" class="popup-container">
        <div class="popup-content">
            <a href="#" @click="closeModal" class="close">×</a>
            <h3 class="popup-title">{{ $t("Feedback") }}</h3>
            <form action="#" @submit.prevent="handleSubmit">
                <input name="message_id" id="message_id" type="hidden">
                <div v-if="$settings.organization.feedback_status == 'advanced'">
                    <div class="">
                        <label for="reason">{{ $t("Reason") }} <span class="text-danger">*</span></label>
                        <select name="reason" id="reason" class="form-control">
                            <option value="" selected disabled hidden>{{ $t("Please Select One") }}</option>
                            <option :value="item.id" v-for="(item, index) in $settings.reasons" :key="index">{{
                                item.name }}</option>
                        </select>
                    </div>
                    <div class="">
                        <label for="correct_answer">{{ $t("Correct Answer") }} <span
                                class="text-danger">*</span></label>
                        <textarea name="correct_answer" id="correct_answer" cols="30" rows="5"
                            class="form-control"></textarea>
                    </div>
                </div>
                <div v-if="$settings.organization.feedback_status == 'standard'">
                    <div class="">
                        <label for="feedback">{{ $t("Feedback") }}<span class="text-danger">*</span></label>
                        <textarea name="feedback" id="feedback" cols="30" rows="5" class="form-control"></textarea>
                    </div>
                </div>
                <div class="save-button">
                    <button type="submit" :disabled="loadingReport" class="btn btn-theme"
                        :style="{ background: $settings.chat_color, color: $settings.text_color }">
                        {{ $t("Save") }}
                    </button>
                </div>
            </form>
        </div>
    </div>

    <div id="popup-attachments" class="popup-container">
        <div class="popup-content">
            <a href="#" @click="closeModal" class="close">×</a>
            <h3 class="popup-title">{{ $t("Attachments") }}</h3>
            <p>{{$t('Select the type of file you want to upload')}}</p>
            <select class="form-control" id="file-type-selection" @change="handleFileTypeChange">
                <option disabled selected value="0">{{$t('Select file type')}}</option>
                <option :value="JSON.stringify(item)"
                    v-for="(item, index) in $settings.organization.requiredAttachments" :key="index"
                    :disabled="uploadedFilesNames.includes(item.attachment.name)">{{ item.attachment.name }} {{
                    uploadedFilesNames.includes(item.attachment.name) ? "("+$t('already uploaded')+")": "" }}</option>
            </select>
            <input type="hidden" id="selected-file-type" v-model="selectedFileType" />
            <div class="popup-footer">
                <button type="button" class="btn btn-outline-danger" @click="closeModal">{{$t('Cancel')}}</button>
                <button type="button" class="btn btn-theme" :style="{ background: $settings.chat_color }"
                    id="confirmFileType" :disabled="!isFileTypeSelected"
                    @click="onConfirmClick">{{$t('Confirm')}}</button>
            </div>
        </div>
    </div>

    <!-- preview image -->
    <div class="show_imgs">
        <div class="overlay" @click="closePreview"></div>
        <div class="img-show">
            <span class="close" @click="closePreview"><i class="fa-regular fa-circle-xmark"></i></span>
            <img src="">
        </div>
    </div>

</template>

<script>

import helpers from "../helpers.js";

export default {
    name: 'Component_3',
    data() {
        return {
            messages: [],
            question: "",
            showFloatingDiv: false,
            token: "",
            chatId: false,
            loading: false,
            loadingReport: false,
            errors: {},
            files: [],
            selectedFileType: '',
            acceptString: '',
            isFileTypeSelected: false,
            deleteFileId: "",
            shouldDisplayPreview: false,
            uploadedFilesNames: [],
            showForm: true,
        }
    },
    mounted() {
        this.token = this.$settings.token;
        this.chatId = localStorage.getItem(this.token) || false;
        if(this.$settings.has_initial_message){
            this.messages.push({
                "role": "bot",
                "message": this.$settings.initial_message,
                "time": helpers.getCurrentTime(),
                "message_id": false,
                "allow_feedback": false,
                "message_type": "text",
            });
        }
        if(this.$oldChat){
            let uploadContainer = document.getElementById('upload-container');
            if(uploadContainer){
                if (this.$oldChat.isAttachmentAllowed) {
                    uploadContainer.style.display = 'block';
                } else {
                    uploadContainer.style.display = 'none';
                }
            }

            this.uploadedFilesNames = [];
            this.$oldChat.uploadedAttachments.forEach(attachment => {
                this.uploadedFilesNames.push(attachment.type);
            });

            this.shouldDisplayPreview = this.uploadedFilesNames.length > 0;

            this.$oldChat.messages.forEach(message => {
                if (message.role == "function" && message.name == "upload" ) {
                    let fileExtension = message.file.url ? this.getFileExtension(message.file.url): "none";
                    this.messages.push({
                        "role": "user",
                        "time": message.time,
                        "message_id": message.id,
                        "allow_feedback": message.report_count ? false: true,
                        "message_type": "file",

                        "file_id": message.file.file ? "upload-"+message.file.file.id : "",
                        "file_source": message.file.url,
                        "file_type": message.file.file ? message.file.file.type : "",
                        "loading": false,

                        "file_extension": fileExtension,
                        "deleted": message.file.file ? false: true,
                    });
                    if(message.file.file){
                        this.files.push({
                            id: message.file.file.id,
                            url: message.file.url,
                            name: message.file.file.type,
                            is_image: (fileExtension == "image"),
                        });
                    }
                } else {
                    this.messages.push({
                        "role": message.role,
                        "message": message.content,
                        "time": message.time,
                        "message_id": message.id,
                        "allow_feedback": message.report_count ? false: true,
                        "message_type": "text",
                    });
                }
            });
        }
    },
    methods: {
        handleFileTypeChange(event) {
            this.isFileTypeSelected = false;
            const attachment = helpers.tryJSON(event.target.value);
            if (!attachment) return;

            const allowedExtensions = Object.values(attachment.extensions || {});
            const formattedExtensions = allowedExtensions.map(ext => '.' + ext.toLowerCase());
            this.acceptString = formattedExtensions.join(', ');

            this.selectedFileType = attachment.attachment.name;
            this.isFileTypeSelected = true;
        },
        onConfirmClick() {
            if(!this.loading){
                // Trigger click on file input
                this.$refs.uploadFiles.click();
                // Call the method to close the modal
                helpers.closeModal();
            }
        },  
        previewImage (event) {
            var url = event.target.closest("span").getAttribute('url');
            let extension = this.getFileExtension(url)
            if(extension == "image"){
                this.fadeIn(document.querySelector('.show_imgs'));
                document.querySelector('.img-show img').setAttribute('src', url);
            } else {
                // Otherwise open the url in seperated page.
                window.open(url, '_blank');
            }
        },
        closePreview () {
            this.fadeOut(document.querySelector('.show_imgs'));
        },
        reloadPage() {
            window.location.reload();
        },
        fadeIn(el) {
            el.style.opacity = 0;
            el.style.display = "block";

            (function fade() {
                var val = parseFloat(el.style.opacity);
                if (!((val += 0.1) > 1)) {
                    el.style.opacity = val;
                    requestAnimationFrame(fade);
                }
            })();
        },
        fadeOut(el) {
            el.style.opacity = 1;

            (function fade() {
                if ((el.style.opacity -= 0.1) < 0) {
                    el.style.display = "none";
                } else {
                    requestAnimationFrame(fade);
                }
            })();
        },
        deleteFile (event){
            event.preventDefault();
            if(!this.loading){
                let fileIndex = event.target.closest("span.file-item").getAttribute('fileIndex');
                // console.log({fileIndex});
                this.deleteFileId = fileIndex;
                this.sendQuestion();
            }
        },
        handleFilesUpload(event) {
            // Add the selected files to the files array
            for (let i = 0; i < event.target.files.length; i++) {
                let file = event.target.files[i];
                this.files.push({
                    url: URL.createObjectURL(file),
                    name: file.name,
                    type: file.type,
                    is_image: file.type.startsWith('image/'),
                });
            }
        },
        handleClick(event) {
            const parentSpan = event.target.closest('span');
            const messageId = parentSpan.getAttribute('message_id');
            const inputField = document.getElementById('message_id');
            inputField.value = messageId;
            if(this.$settings.organization.feedback_status == 'advanced'){
                document.getElementById('reason').value = "";
                document.getElementById('correct_answer').value  = "";
            } else {
                document.getElementById('feedback').value  = "";
            }
        },
        customSubmit(event) {
            // Prevent the default form submission behavior
            event.preventDefault();
            this.sendQuestion();
        },
        closeModal(event){
            event.preventDefault();
            helpers.closeModal();
        },
        rgbaFromHex(hex, alpha){
            return helpers.rgbaFromHex(hex, alpha);
        },
        backgroundLinear(chat_color) {
            return `linear-gradient(135deg, ${chat_color} 35%, rgba(255, 255, 255, 1) 100%)`;
        },
        handleSubmit(event){
            if(this.loadingReport){
                // DO not do any think if there is a report beening sent now.
                return;
            }
            var formData = new FormData(event.target);
            // If you want to create an object instead:
            var formObject = {};
            for (var pair of formData.entries()) {
                formObject[pair[0]] = pair[1];
            }
            this.errors = {};
            if(this.$settings.organization.feedback_status == 'advanced'){
                if(!formObject.reason){
                    this.errors.reason = this.$t("reason_required");
                }
                // Add if condition to check correct_answer.
                if(!formObject.correct_answer){
                    this.errors.correct_answer = this.$t("correct_answer_required");
                }
            } else {
                if(!formObject.feedback){
                    this.errors.feedback = this.$t("feedback_required");
                }
            }
            // Check if errors is not empty, then return.
            if(Object.keys(this.errors).length > 0){
                return;
            }
            if(this.$settings.organization.allow_feedback){
                this.loadingReport = true;
                fetch(process.env.VUE_APP_BASE_URL+"api/organization/report/"+this.token, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                    },
                    method: "POST",
                    body: JSON.stringify(formObject),
                })
                .then(response => {
                    this.loadingReport = false;
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    return response.json();
                })
                .then(data => {
                    if(data.status){
                        helpers.closeModal();
                        for(let i = 0 ; i < this.messages.length; i++){
                            let message = this.messages[i];
                            if(message.message_id == formObject.message_id){
                                message.allow_feedback = false;
                                break;
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                });

            }
        },
        async sendQuestion() {

            this.lockInputs();

            let question = this.question;
            let file = this.$refs.uploadFiles.value.trim();
            let file_type = this.selectedFileType;
            this.isFileTypeSelected = false;
            let deleteFileId = this.deleteFileId;
            this.deleteFileId = "";
            if(this.loading || (!question && !file && !deleteFileId)){
                this.unlockInputs();
                return;
            }
            this.question = "";

            const fileExtension = file ? this.getFileExtension(file) : null;

            if(file){
                let blobFile = this.$refs.uploadFiles.files[0];
                this.messages.push({
                    "role": "user",
                    "time": helpers.getCurrentTime(),
                    "message_id": false,
                    "allow_feedback": false,
                    "message_type": "file",

                    "file_id": "upload-0",
                    "file_source": URL.createObjectURL(blobFile),
                    "file_type": file_type,
                    "loading": true,

                    "file_extension": fileExtension,
                    "deleted": false,
                });

            }else if (deleteFileId != ''){

                // delete the span in the preview files with dateindex = deleteFileId
                var container = document.getElementById('files-list-container');
                var spanToRemove = container.querySelector('span[fileIndex="' + deleteFileId + '"]');
                if (spanToRemove) {
                    container.removeChild(spanToRemove);
                }
                this.messages.push({
                    "role": "user",
                    "message": this.$t("Request delete file"),
                    "time": helpers.getCurrentTime(),
                    "message_id": false,
                    "allow_feedback": false,
                    "message_type": "text",
                });

            }else{
                this.messages.push({
                    "role": "user",
                    "message": helpers.escapeHTML(question),
                    "time": helpers.getCurrentTime(),
                    "message_id": false,
                    "allow_feedback": false,
                    "message_type": "text",
                });
            }

            this.messages.push({
                "role": "bot",
                "message": "",
                "time": "",
                "message_id": false,
                "allow_feedback": false,
                "message_type": "text",
            });
            this.scrollToBottom();
            this.loading = true;

            const body = new FormData();
            body.append("message_source", "chatcomponent")
            body.append("organization_id", this.$settings.organization.id)
            body.append("component_id", this.$settings.component_id)
            body.append("message", question)
            body.append("model_type_id", this.$settings.model_type_id)

            if(this.chatId){
                body.append("chat_id", this.chatId)
            }
            const headers = {
                "X-Requested-With": "XMLHttpRequest",
            };
            if (file) {
                headers['enctype'] = 'multipart/form-data';
                let blobFile = this.$refs.uploadFiles.files[0];
                body.append("file", blobFile);
                body.append("file_type", file_type);
                this.$refs.uploadFiles.value = '';
            }

            if(deleteFileId){
                body.append("delete_file_id", deleteFileId)
            }

            fetch(process.env.VUE_APP_BASE_URL+"api/organization/message/"+this.token, {
                headers: headers,
                method: "POST",
                body: body,
            })
            .then(async (res) => {
                if (!res.ok) {
                    let response = await res.json();
                    if(response && response.key == 'chat_limit_reached'){
                        this.messages[this.messages.length - 1].message = response.message;
                    } else {
                        this.messages[this.messages.length - 1].message = "Internal error occur in the system";
                    }
                    this.showForm = false;
                    this.loading = false;
                    this.unlockInputs();
                    return;
                }
                const reader = res.body.getReader();
                const decoder = new TextDecoder();

                let loop = true;
                let fullMessage = "";
                while (loop) {
                    const { value, done } = await reader.read();
                    if (done) break;
                    let text = decoder.decode(value, { stream: true });

                    let chunkResponse = helpers.tryJSON(text);
                    let chunkList = [chunkResponse];
                    if(chunkResponse == null){
                        chunkList = helpers.tokenizeJsons(text);
                    }

                    for (let i = 0; i < chunkList.length; i++) {
                        const chunk = chunkList[i];
                        if (chunk.type == "meta") {
                            this.executeResponse(chunk.content);
                        } else if (chunk.type == "uploaded_files") {
                            this.executeFileUploaded(chunk.content, fileExtension);
                        } else if (chunk.type == "file_deleted") {
                            this.executeFileDeleted(chunk.content);
                        } else {
                            fullMessage += chunk.content;
                            this.messages[this.messages.length - 1].message = await helpers.markdownToHtml(fullMessage);
                        }
                        this.scrollToBottom();
                    }
                    this.scrollToBottom();
                }
                this.scrollToBottom();
                this.unlockInputs();
            })
            .catch(error => {
                this.unlockInputs();
                console.error('Fetch error:', error);
            });
        },
        toggleFloatingDiv() {
            this.showFloatingDiv = !this.showFloatingDiv;
            this.scrollToBottom();
        },
        fullscreen() {
            var chatBubble = document.querySelector('.Layout');
            var fullscreenButton = chatBubble.querySelector('#fullscreen');
            var fullscreenIcon = fullscreenButton.querySelector('#fullscreen-icon');
            //fullscreenButton.addEventListener('click', function () {
                chatBubble.classList.toggle('fullscreen');
                if (chatBubble.classList.contains('fullscreen')) { 
                    fullscreenIcon.classList.remove('fa-expand');
                    fullscreenIcon.classList.add('fa-compress');
                }
                else {
                    fullscreenIcon.classList.add('fa-expand');
                    fullscreenIcon.classList.remove('fa-compress');
                }
            //});
        },
        getFileExtension(file) {
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
            const pdfExtensions = ['pdf'];
            const wordExtensions = ['doc', 'docx'];
            const excelExtensions = ['xls', 'xlsx'];

            const fileExtension = file.split('.').pop().toLowerCase();

            if (imageExtensions.includes(fileExtension)) {
                return 'image';
            } else if (pdfExtensions.includes(fileExtension)) {
                return 'pdf';
            } else if (wordExtensions.includes(fileExtension)) {
                return 'word';
            } else if (excelExtensions.includes(fileExtension)) {
                return 'excel';
            } else {
                return 'file';
            }
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.messagesList;
                container.scrollTop = container.scrollHeight;
            });
        },
        executeResponse(response) {
            this.loading = false;
            if(response && response.status){
                this.chatId = response.chat_id;
                localStorage.setItem(this.token, response.chat_id);
                let message_id = response.message_id;
                let time = response.time;
                let message = this.messages[this.messages.length - 1]
                message.time = time ? time: helpers.getCurrentTime()
                message.message_id = message_id
                message.allow_feedback = this.$settings.organization.allow_feedback

                if (response.is_attachment_allowed) {
                    document.getElementById('upload-container').style.display = 'block';
                } else {
                    document.getElementById('upload-container').style.display = 'none';
                }
            }
        },
        executeFileUploaded(uploadedFiles, fileExtension) {
            const uploadedFilesNames = helpers.tryJSON(uploadedFiles['uploadedFilesNames']);
            const recentUploadedFile = helpers.tryJSON(uploadedFiles['recentUploadedFile']);
            const source = uploadedFiles['source'];

            this.uploadedFilesNames = uploadedFilesNames;

            let userMessage = this.messages[this.messages.length - 2];
            userMessage.file_source = source;
            userMessage.loading = false;
            userMessage.file_id = "upload-"+recentUploadedFile['id']
            userMessage.file_type = recentUploadedFile['type']
            this.messages[this.messages.length - 2] = userMessage;

            this.shouldDisplayPreview = true;

            // const uploadedFilesContainer = document.getElementById('files-list-container');
            this.files.push({
                id: recentUploadedFile['id'],
                url: source,
                name: recentUploadedFile['type'],
                is_image: (fileExtension == "image"),
            });
        },
        executeFileDeleted(uploadedFiles) {
            const uploadedFilesNames = helpers.tryJSON(uploadedFiles['uploadedFilesNames']);
            const recentDeletedFileId = uploadedFiles['recentDeletedFileId'];

            this.uploadedFilesNames = uploadedFilesNames;

            // deleted
            for(let i = 0 ; i < this.messages.length; i++){
                let message = this.messages[i];
                if(message.message_type == "file" && message.role == "user" && message.file_id == "upload-"+recentDeletedFileId){
                    message.deleted = true;
                }
            }

            if(uploadedFilesNames.length < 1){
                this.shouldDisplayPreview = false;
            }
        },
        lockInputs() {
            console.log('sdsfds')
            document.querySelectorAll('.lockable-input').forEach((input) => {
                if (input.tagName === 'INPUT') {
                    input.readOnly = true;
                } else {
                    input.disabled = true;
                }

                // Remove any error messages
                input.classList.remove('is-invalid');
                input.parentElement.querySelectorAll('.is-invalid').forEach((error) => {
                    error.remove();
                });
            });
        },
        unlockInputs() {
            document.querySelectorAll('.lockable-input').forEach((input) => {
                if (input.tagName === 'INPUT') {
                    input.readOnly = false;
                } else {
                    input.disabled = false;
                }
            });
        }
    },
}

</script>