import { unified } from 'unified';
import markdown from 'remark-parse';
import remark2rehype from 'remark-rehype';
import rehypeStringify from 'rehype-stringify';


function getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return hours + ':' + minutes + ' ' + ampm;
}

function tryJSON(str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return null;
    }
}

function closeModal() {
    location.hash = "";
}

function rgbaFromHex(hex, alpha) {
    // Remove the # character if it's present
    hex = hex.replace('#', '');
    // Parse the hex value into RGB components
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    // Return the RGBA value with the specified alpha
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

async function markdownToHtml(markdownString) {
    const result = await unified()
        .use(markdown)
        .use(remark2rehype)
        .use(rehypeStringify)
        .process(markdownString);

    return result.value.toString();
}

function escapeHTML(str) {
    if(!str){
        return str;
    }
    return str.replace(/[&<>'"]/g, function (tag) {
        return {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            "'": '&#39;',
            '"': '&quot;'
        }[tag] || tag;
    });
}
function tokenizeJsons(jsonString) {
    let formattedString = jsonString.replace(/}{/g, '},{');

    formattedString = '[' + formattedString + ']';

    let jsonArray;
    try {
        jsonArray = JSON.parse(formattedString);
    } catch (error) {
        console.error('Error parsing JSON:', error);
        return [];
    }
    return jsonArray;
}

export default {
    getCurrentTime,
    tryJSON,
    closeModal,
    rgbaFromHex,
    markdownToHtml,
    escapeHTML,
    tokenizeJsons,
}
