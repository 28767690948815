import { createApp, reactive } from 'vue';
import { createI18n } from 'vue-i18n';
// import ChatWidget from './ChatWidget.vue'

import Component1 from './components/Component1.vue'
import Component2 from './components/Component2.vue'
import Component3 from './components/Component3.vue'
import Component4 from './components/Component4.vue'
import Component5 from './components/Component5.vue'
import Component6 from './components/Component6.vue'

// Import your translation files
import en from './locales/en.json';
import ar from './locales/ar.json';
import './assets/chat-widget.css';

const currentScript = document.currentScript;
let siteLanguage = "en";
if (currentScript) {
    var lang = currentScript.getAttribute('data-lang');
    if(lang){
        siteLanguage = lang;
    }
} else {
    siteLanguage = navigator.language.split('-')[0]
}
// this.$i18n.locale = siteLanguage;
const i18n = createI18n({
    locale: siteLanguage,
    fallbackLocale: 'en',
    messages: {en, ar},
});

if (currentScript) {
    let token = currentScript.getAttribute('data-token');
    fetch(process.env.VUE_APP_BASE_URL+"api/organization/themes/"+token, {
        method: "GET",
    }).then(response => {
        if (response.status === 442) {
            return response.json();
        } else if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json(); // Parse the response body as JSON
    }).then(async (data) => {

        let response = data;
        // Attach the code to 
        if(response.status && response.component.chatbot_type == "chat_bubble"){
            let component = response.component;
            component.token = token;
            const globalSettings = reactive(component);
            let type = Component1;
            if(component.chat_style == "style1"){
                type = Component1;
            } else if(component.chat_style == "style2") {
                type = Component2;
            } else if(component.chat_style == "style3") {
                type = Component3;
            }
            else if (component.chat_style == "style4") {
                type = Component4;
            }
            else if (component.chat_style == "style5") {
                type = Component5;
            }
            else if (component.chat_style == "style6") {
                type = Component6;
            }

            var customId = "chat-bubble-container";
            var chat_bubble = document.createElement("div");
            chat_bubble.id = customId;
            if(siteLanguage == "ar"){
                var customClass = "rtl";
                chat_bubble.classList = customClass; 
            }
            document.body.appendChild(chat_bubble);

            var chatId = localStorage.getItem(token) || false;

            if(chatId){
                fetch(process.env.VUE_APP_BASE_URL+"api/organization/message/"+chatId, {
                    method: "GET",
                }).then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // Parse the response body as JSON
                }).then(async (data) => {
                    const app = createApp(type)
                    app.config.globalProperties.$settings = globalSettings;
                    app.config.globalProperties.$oldChat = data;
                    app.use(i18n);
                    app.mount('#'+customId)
                }).catch(error => {
                    // Handle any errors that occurred during the fetch
                    console.error('Fetch error:', error);
                });
            } else {
                const app = createApp(type)
                app.config.globalProperties.$settings = globalSettings;
                app.config.globalProperties.$oldChat = null;
                app.use(i18n);
                app.mount('#'+customId);
            }
        }
    }).catch(error => {
        // Handle any errors that occurred during the fetch
        console.error('Fetch error:', error);
    });

}


